<template>
	<div>
		<GuestBookingOptions
      v-if="['guest', 'both'].includes(user.type) && userHasGuest"
      @saved="guestBookingOptionsSaved = true"
    />

    <ShowBookingOptions
      v-if="['show', 'both'].includes(user.type) && userHasShow"
      @saved="showBookingOptionsSaved = true"
    />
	</div>
</template>

<script>
  import { get } from 'lodash'
  import GuestBookingOptions from '@/components/register/GuestBookingOptions';
  import ShowBookingOptions from '@/components/register/ShowBookingOptions';

	export default {
		name: "ProfilesAvailability",

    components: {
      GuestBookingOptions,
      ShowBookingOptions,
    },

    data() {
      return {
        guestBookingOptionsSaved: false,
        showBookingOptionsSaved: false,
      }
    },

    computed: {
      currentStep() {
        return this.$store.getters['register/currentStep']
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      needsBoth() {
        return this.user.type == 'both' && this.userHasGuest && this.userHasShow
      },

      userHasGuest() {
        return !! get(this.user, 'guest', null)
      },

      userHasShow() {
        return !! get(this.user, 'shows', []).length
      }
    },

    watch: {
      guestBookingOptionsSaved(guestBookingOptionsSaved) {
        if (! guestBookingOptionsSaved) {
          return
        }

        if (this.needsBoth && ! this.showBookingOptionsSaved) {
          return
        }

        this.finishStep()
      },

      showBookingOptionsSaved(showBookingOptionsSaved) {
        if (! showBookingOptionsSaved) {
          return
        }

        if (this.needsBoth && ! this.guestBookingOptionsSaved) {
          return
        }

        this.finishStep()
      },
    },

    methods: {
      finishStep() {
        if (this.user.status === 'pending') {
          return this.$router.push({ name: 'CheckEmail' });
        }

        this.$router.push({name: 'ThankYou'})
      }
    },

		created() {
      if (! this.user.id) {
        return this.$router.push({name: 'Dashboard'})
      }

      if (! this.userHasGuest && ! this.userHasShow) {
        return this.finishStep()
      }

      this.$store.commit('register/setCurrentStep', 7)

      window.Event.$on('saveAvailability', () => {
        window.Event.$emit('saveGuestAvailability')
        window.Event.$emit('saveShowAvailability')
      });
		},

    beforeDestroy() {
      window.Event.$off('saveAvailability')
    }
	}
</script>
