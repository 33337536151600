<template>
  <div>
    <div :class="{'border-t pt-6 mt-6': user.type == 'both'}">
      <h3 class="text-xl text-blue-800 font-bold">
        Booking Options (Shows)
      </h3>
      <p class="text-gray-500 mt-2">
        Choose what type of Booking you want to offer to the Guest that Book you and how much you will charge them.
      </p>
    </div>
    <div class="mt-12" :class="user.type == 'both' ? 'mb-12' : 'mb-20'">
      <div v-if="working" class="py-6 flex items-center justify-center">
        <loading-icon class="h-2 text-pink-500" />
      </div>
      <div v-else class="max-w-xl w-full">
        <ValidationObserver ref="bookingOptionsForm" v-slot="{ handleSubmit }">
          <form method="post" @submit.prevent="handleSubmit(saveBookingOptions)">
            <div class="space-y-12">
              <template v-for="(option, index) in bookingOptions">
                <component
                  :is="`${option.slug}-option`"
                  :key="`booking-option-${index}`"
                  :option="option"
                  :selected-options="flattenBookingOptions"
                  @updated="updateSelectedOptions(option.id, $event)" />
              </template>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import { chain, get } from 'lodash'
  import GuestAppearanceOption from '@/components/details/show/booking-options/GuestAppearanceOption'
  import SponsorOption from '@/components/details/show/booking-options/SponsorOption'

  export default {
    name: 'ShowBookingOptions',

    components: {
      GuestAppearanceOption,
      SponsorOption,
    },

    data() {
      return {
        working: true,
        bookingOptions: [],
        selectedOptions: {},
      }
    },

    computed: {
      flattenBookingOptions() {
        return chain(this.selectedOptions)
          .flatMap(options => options)
          .value()
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      show() {
        return get(this.user, 'shows.0')
      },
    },

    methods: {
      scrollToFirstError() {
        const el = this.$el.querySelector('.form-error:first-of-type')

        if (! el) {
          return
        }

        el.parentElement.scrollIntoView()
      },

      fetchBookingOptions() {
        api.get('/booking-options?filter[type]=show')
          .then(({ data }) => {
            this.bookingOptions = data.data
          })
          .finally(() => this.working = false)
      },

      updateSelectedOptions(optionId, data) {
        this.$set(this.selectedOptions, optionId, data)
      },

      saveBookingOptions() {
        this.$refs.bookingOptionsForm
          .validate()
          .then(success => {
            if (! success) {
              this.scrollToFirstError()
              return
            }

            api.post(`/shows/${this.show.id}/booking-options`, {
              options: this.flattenBookingOptions,
            })
              .then(() => {
                this.$emit('saved')
              })
              .catch(() => {
                this.$toast.error('Error! Something went wrong.')
              })
          })
      }
    },

    created() {
      this.fetchBookingOptions()
    },

    mounted() {
      window.Event.$on('saveShowAvailability', () => this.saveBookingOptions())
    },

    beforeDestroy() {
      window.Event.$off('saveShowAvailability')
    }
  }
</script>
