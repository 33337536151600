<template>
  <div>
    <div class="flex">
      <div class="hidden sm:flex items-center w-18 flex-shrink-0">
        <button
          type="button"
          class="w-12 h-12 border border-guestio-gray-50 rounded-full flex items-center justify-center shadow-xl"
          :class="{ 'bg-pink-500 border-pink-500': selected.length }"
          @click.prevent="toggleSelection"
        >
          <CheckIcon v-if="selected.length" class="h-8 text-white" />
        </button>
      </div>
      <div @click="toggleSelection" class="cursor-pointer bg-white flex-1 border border-guestio-gray-50 shadow-xl flex rounded-xl px-4 py-6" :class="{ 'border-pink-500': selected.length }">
        <div class="flex-shrink-0 flex justify-end px-3">
          <img :src="option.icon_url" class="h-8 w-8" alt="">
        </div>
        <div class="flex-1">
          <h3 class="font-bold text-xl text-blue-800">{{ option.name }}</h3>
          <p class="text-sm text-gray-500 mt-2">
            Full-length organic interview on my Show.
          </p>
        </div>
      </div>
    </div>

    <div v-if="selected.length" class="pl-0 sm:pl-20 mt-6">
      <div class="flex items-center space-x-12">
        <label class="cursor-pointer select-none flex items-center">
          <input type="radio" class="form-radio h-6 w-6 text-pink-500" value="audio" v-model="variation">
          <span class="ml-2 flex items-center justify-between w-full">Audio</span>
        </label>

        <label class="cursor-pointer select-none flex items-center">
          <input type="radio" class="form-radio h-6 w-6 text-pink-500" value="video" v-model="variation">
          <span class="ml-2 flex items-center justify-between w-full">Video</span>
        </label>
      </div>

      <div class="mt-6">
        <div v-for="(slot, index) in selected" :key="`slot-${index}`" class="flex items-start mb-5">
          <div class="flex items-center">
            <div>
              <ValidationProvider rules="required" :name="`Time slot duration ${index}`" :vid="`guest_time_slot_${index}`" v-slot="{ errors }">
                <div
                  class="rounded-full bg-white py-3 px-5 flex items-center border border-guestio-gray-50"
                  :class="{'border border-red-500': errors.length}"
                >
                  <ClockIcon class="mr-3 flex-shrink-0"/>
                  <select class="bg-transparent" v-model="slot.interview_duration">
                    <option
                      v-for="duration in availableDurations"
                      :value="duration"
                      :key="`duration-option-${duration}`"
                      :hidden="selectedDurations.includes(duration) && duration != slot.interview_duration"
                    >{{ duration }}</option>
                  </select>
                </div>
              </ValidationProvider>
            </div>

            <div class="ml-2">
              <span>minutes</span>
            </div>
          </div>

          <div class="ml-6 xl:ml-12 flex items-center">
            <div>
              <ValidationProvider name="Price" :vid="`guest_price_${index}`" v-slot="{ errors }" rules="required|min_value:5">
                <div
                  class="rounded-full bg-white py-3 px-5 flex items-center w-32 border border-guestio-gray-50"
                  :class="[{'border border-red-500': errors.length}]"
                >
                  <DollarSignIcon class="mr-3 flex-shrink-0"/>
                  <input
                    id="price"
                    type="number"
                    min="5"
                    oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                    class="bg-transparent w-full"
                    v-model="slot.price"
                  >
                </div>
                <p class="form-error text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <div v-if="index > 0" class="ml-6">
            <button @click.prevent="removeInterviewDuration(index)" class="rounded-full bg-gray-50 h-10 w-10 flex justify-center items-center">
              <CrossIcon/>
            </button>
          </div>
        </div>

        <div class="mt-5">
          <button
            v-if="remainingDurations.length "
            @click.prevent="addInterviewDuration"
            type="button"
            class="text-pink-500 underline block"
          >
            Add Additional Time Slots +
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CheckIcon from '@/components/svgs/CheckIcon'
  import ClockIcon from "@/components/svgs/ClockIcon";
  import DollarSignIcon from "@/components/svgs/DollarSignIcon";
  import CrossIcon from "@/components/svgs/CrossIcon";

  export default {
    name: 'GuestAppearanceOption',

    props: {
      option: Object,

      prices: {
        type: Array,
        default: () => ([])
      },

      selectedOptions: Array,
    },

    components: {
      CheckIcon,
      ClockIcon,
      DollarSignIcon,
      CrossIcon,
    },

    data() {
      return {
        selected: [],

        variation: 'video',

        defaultItem: {
          booking_option_id: this.option.id,
          price: 10,
          interview_duration: 30,
        }
      }
    },

    watch: {
      selected: {
        deep: true,
        handler(selected) {
          this.$emit('updated', selected.map(option => ({...option, variation: this.variation})))
        }
      },

      variation(variation) {
        this.$emit('updated', this.selected.map(option => ({...option, variation})))
      },
    },

    computed: {
      availableDurations() {
        return this.$store.getters['guest/availability/availableDurations']
      },

      selectedDurations() {
        return this.selected.map(slot => slot.interview_duration)
      },

      remainingDurations() {
        return this.availableDurations.filter(duration => ! this.selectedDurations.includes(duration))
      }
    },

    methods: {
      toggleSelection() {
        if (this.selected.length) {
          this.selected = []
          return
        }

        this.selected.push({
          ...this.defaultItem
        })
      },

      addInterviewDuration() {
        if (! this.remainingDurations.length) {
          return
        }

        this.selected.push({
          interview_duration: this.remainingDurations[0] || null,
          price: 10,
          booking_option_id: this.option.id,
        })
      },

      removeInterviewDuration(index) {
        this.selected.splice(index, 1)
      },
    },

    mounted() {
      if (this.prices && this.prices.length) {
        this.selected = [...this.prices]
        this.variation = this.prices[0].variation || 'video'
      }
    }
  }
</script>
